import { Box, Text, Table, Thead, Tbody, Tr, Th, Td, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { identifyTone } from './identifyTone';

const DisplayData = ({ frequencyData }) => {
  const [sortedData, setSortedData] = useState([]);
  const [dominantFrequency, setDominantFrequency] = useState(null);
  const [tone, setTone] = useState('');
  const [frequencyRange, setFrequencyRange] = useState({});
  const [averageFrequency, setAverageFrequency] = useState(null);

  // Calculate the necessary features once the frequency data is available
  useEffect(() => {
    if (frequencyData.length > 0) {
      const sorted = [...frequencyData].sort((a, b) => a - b);
      setSortedData(sorted);
      
      const dominant = sorted[sorted.length - 1]; // Assume the dominant frequency is the highest one
      setDominantFrequency(dominant);
      setTone(identifyTone(dominant));
      
      const minFrequency = Math.min(...sorted);
      const maxFrequency = Math.max(...sorted);
      setFrequencyRange({ min: minFrequency, max: maxFrequency });

      const avgFrequency = sorted.reduce((sum, freq) => sum + freq, 0) / sorted.length;
      setAverageFrequency(avgFrequency);
    }
  }, [frequencyData]);

  const downloadData = () => {
    const csvData = frequencyData
      .map((freq, index) => `Time: ${index}, Frequency: ${freq} Hz`)
      .join('\n');
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'frequency-data.csv';
    link.click();
  };

  return (
    <Box>
      <Text fontSize="xl" fontWeight="bold">Analysis Report</Text>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Data Type</Th>
            <Th>Details</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td>Dominant Frequency</Td>
            <Td>{dominantFrequency ? `${dominantFrequency} Hz` : 'Calculating...'}</Td>
          </Tr>
          <Tr>
            <Td>Identified Tone</Td>
            <Td>{tone || 'Calculating...'}</Td>
          </Tr>
          <Tr>
            <Td>Frequency Range</Td>
            <Td>{`Min: ${frequencyRange.min || 'Calculating...'} Hz, Max: ${frequencyRange.max || 'Calculating...'} Hz`}</Td>
          </Tr>
          <Tr>
            <Td>Average Frequency</Td>
            <Td>{averageFrequency ? `${averageFrequency.toFixed(2)} Hz` : 'Calculating...'}</Td>
          </Tr>
          <Tr>
            <Td>Sorted Frequencies</Td>
            <Td>{sortedData.join(', ') || 'Calculating...'}</Td>
          </Tr>
        </Tbody>
      </Table>
      <Button colorScheme="green" mt={4} onClick={downloadData}>
        Download Frequency Data
      </Button>
    </Box>
  );
};

export default DisplayData;
