export const identifyTone = (frequency) => {
    const tones = [
      { note: 'C4', freq: 261.63 },
      { note: 'D4', freq: 293.66 },
      { note: 'E4', freq: 329.63 },
      { note: 'F4', freq: 349.23 },
      { note: 'G4', freq: 392.00 },
      { note: 'A4', freq: 440.00 },
      { note: 'B4', freq: 493.88 },
    ];
  
    const closestTone = tones.reduce((prev, curr) => {
      return Math.abs(curr.freq - frequency) < Math.abs(prev.freq - frequency) ? curr : prev;
    });
  
    return closestTone.note;
  };
  