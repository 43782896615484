import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const FrequencyGraph = ({ frequencyData }) => {
  const data = frequencyData.map((value, index) => ({
    time: `Time ${index}`,
    frequency: value,
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="frequency" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default FrequencyGraph;
