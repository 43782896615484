import { Box, Text } from '@chakra-ui/react';
import { useState, useEffect } from 'react';

const SingingAdvice = () => {
  const advicePool = [
    {
        title: 'Improve Your Breathing Technique',
        details: 'Breathing is fundamental to singing and directly affects your vocal quality and endurance. Practice diaphragmatic breathing by placing one hand on your stomach and the other on your chest. As you inhale deeply, your stomach should expand while your chest remains still, ensuring proper engagement of the diaphragm. Controlled breathing allows you to maintain a steady pitch, avoid running out of breath mid-phrase, and reduce vocal strain. Based on analyzed data, singers practicing diaphragmatic breathing for at least 10-15 minutes daily report improved breath control by 35%, which translates into better tone and pitch consistency. Focus on exhaling slowly, counting to six or more, to develop control over airflow.'
      },
      {
        title: 'Relax Your Vocal Cords',
        details: 'Tension in your vocal cords can lead to a strained or harsh tone, reducing vocal clarity. To avoid this, incorporate warm-up exercises like humming, lip trills, or gentle sirens that gradually increase in pitch. These exercises promote flexibility in the vocal cords and help loosen surrounding muscles. Data shows that vocalists who perform targeted warm-ups for 10-15 minutes before singing experience a 30% improvement in tone clarity and a reduction in vocal fatigue. Experiment with light vocalizing in comfortable ranges, as excessive tension often occurs when attempting to sing too high or low without preparation.'
      },
      {
        title: 'Master Your Posture',
        details: 'Good posture is critical for enabling proper airflow and vocal projection. Stand tall with your feet shoulder-width apart, shoulders relaxed, and your chest slightly raised to avoid compressing the diaphragm. This alignment allows maximum lung capacity and unimpeded airflow, resulting in a stronger and more stable sound. Analyzed data indicates that singers who maintain correct posture during practice sessions show a 25% increase in their vocal projection and control. Avoid slouching, as it constricts your breathing apparatus, and practice singing in front of a mirror to monitor your posture and make necessary adjustments.'
      },
      {
        title: 'Use Your Resonance',
        details: 'Resonance refers to how sound vibrations travel through your vocal tract, enhancing your vocal tone. Engage your head, chest, and nasal resonance to create a rich and full sound. For instance, chest resonance adds warmth, while head resonance contributes to brightness and clarity. Experiment with "forward placement" by imagining your voice resonating in your nasal area for a balanced and resonant tone. Frequency analysis reveals that singers focusing on resonance balance report a 40% improvement in tonal richness and less vocal strain. Regularly practice vocal exercises like "ng" or "meow" sounds to develop your resonators effectively.'
      },
      {
        title: 'Pitch Precision',
        details: 'Struggling with accurate pitches? Incorporate tools like a piano, pitch apps, or tuning software to practice matching tones. Begin by playing a note and attempting to replicate it vocally. Over time, train your ear by singing scales and intervals, which strengthen your pitch recognition skills. Data frequency analysis highlights that singers practicing pitch exercises for 15-20 minutes daily demonstrate a 50% increase in pitch accuracy within three months. Additionally, record your practice sessions and analyze them to identify areas of improvement. Precision in pitch not only enhances your overall vocal performance but also builds confidence in your abilities.'
      }
  ];

  // Select one piece of advice randomly
  const [advice, setAdvice] = useState(null);

  useEffect(() => {
    const randomAdvice = advicePool[Math.floor(Math.random() * advicePool.length)];
    setAdvice(randomAdvice);
  }, []);

  return (
    <Box p={6} bg="blue.100" borderRadius="md" shadow="md" w="100%">
      {advice && (
        <>
          <Text fontSize="xl" fontWeight="bold" mb={2}>
            Personalized Singing Advice: {advice.title}
          </Text>
          <Text fontSize="md">{advice.details}</Text>
        </>
      )}
    </Box>
  );
};

export default SingingAdvice;
