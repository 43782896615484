import { useEffect } from 'react';
import * as Tone from 'tone';

const FrequencyAnalyzer = ({ setFrequencyData }) => {
  useEffect(() => {
    const fft = new Tone.FFT(2048);
    const mic = new Tone.UserMedia();

    mic.open().then(() => {
      mic.connect(fft);
      Tone.Transport.start();

      const interval = setInterval(() => {
        const values = fft.getValue();
        const dominantFrequency = findDominantFrequency(values);
        setFrequencyData((prevData) => [...prevData, dominantFrequency]);
      }, 500);

      return () => {
        clearInterval(interval);
        mic.disconnect();
      };
    });

    return () => {
      Tone.Transport.stop(); // Ensure Tone.js stops when unmounted or when analysis stops
    };
  }, [setFrequencyData]);

  const findDominantFrequency = (values) => {
    return values.reduce((a, b) => (Math.abs(a) > Math.abs(b) ? a : b));
  };

  return null;
};

export default FrequencyAnalyzer;
