import { Box, Text, List, ListItem, ListIcon } from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';

const VocalHealthTips = () => {
  const tips = [
    'Stay hydrated. Drink at least 8 glasses of water daily.',
    'Avoid shouting or overusing your voice.',
    'Warm up your voice with scales and humming exercises.',
    'Rest your voice when feeling strained.',
    'Avoid caffeine and alcohol before singing sessions.',
    'Practice proper posture to support vocal projection.',
  ];

  return (
    <Box p={4} bg="green.50" borderRadius="md" shadow="sm" w="100%">
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        Vocal Health Tips
      </Text>
      <List spacing={3}>
        {tips.map((tip, index) => (
          <ListItem key={index}>
            <ListIcon as={StarIcon} color="green.500" />
            {tip}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default VocalHealthTips;
