import { useState } from 'react';
import { VStack, Box, Button, Heading, Divider, Flex, Text, HStack, Stack, Spacer, Center, Icon } from '@chakra-ui/react';
import { FaMicrophoneAlt, FaMusic } from 'react-icons/fa';
import FrequencyAnalyzer from './FrequencyAnalyzer';
import DisplayData from './DisplayData';
import FrequencyGraph from './FrequencyGraph';
import VocalHealthTips from './VocalHealthTips';
import SingingAdvice from './SingingAdvice';

function App() {
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [frequencyData, setFrequencyData] = useState([]);
  const [capturedData, setCapturedData] = useState([]);
  const [audioStopped, setAudioStopped] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const startAnalysis = () => {
    setIsAnalyzing(true);
    setAudioStopped(false);
    setFrequencyData([]);
  };

  const stopAnalysis = () => {
    setIsAnalyzing(false);
    setIsProcessing(true);

    setTimeout(() => {
      setIsProcessing(false);
      setCapturedData([...frequencyData]);
      setAudioStopped(true);
    }, 2000);
  };

  const restartAnalysis = () => {
    window.location.reload();
  };

  return (
    <VStack spacing={10} p={8} bgGradient="linear(to-b, red.50, red.100)" minH="100vh">
      {/* Header */}
      <Flex
        w="100%"
        justify="space-between"
        align="center"
        p={8}
        bgGradient="linear(to-r, red.400, red.600)"
        color="white"
        borderRadius="2xl"
        shadow="2xl"
      >
        <Heading size="lg">🎸 Real-Time Singing Analysis and Frequency Detection </Heading>
        {audioStopped && (
          <Button
            colorScheme="red"
            variant="solid"
            onClick={restartAnalysis}
            _hover={{ bg: 'red.700', color: 'white' }}
            leftIcon={<FaMusic />}
            size="lg"
          >
            Start Singing Again
          </Button>
        )}
      </Flex>

      {/* Main Controls */}
      <Center w="100%">
        {!isAnalyzing && !audioStopped && (
          <VStack spacing={10} align="center">
            <Button
              size="xl"
              colorScheme="red"
              borderRadius="full"
              p={20}
              shadow="2xl"
              onClick={startAnalysis}
              _hover={{ bg: 'red.700', transform: 'scale(1.15)', shadow: 'xl' }}
              leftIcon={<Icon as={FaMicrophoneAlt} boxSize={10} />}
            >
              Start Singing
            </Button>
            <Text fontSize="3xl" color="gray.800" mt={4} textAlign="center">
              Ready to analyze your voice? Press the button to get real-time insights and improve your singing skills!
            </Text>
          </VStack>
        )}
        {isAnalyzing && (
          <VStack spacing={8} align="center">
            <Text fontSize="3xl" color="red.700" mb={4}>
              Analyzing your voice...
            </Text>
            <Button
              size="xl"
              colorScheme="red"
              borderRadius="full"
              p={16}
              shadow="2xl"
              onClick={stopAnalysis}
              _hover={{ bg: 'red.800', transform: 'scale(1.15)', shadow: 'xl' }}
              leftIcon={<Icon as={FaMicrophoneAlt} boxSize={10} />}
            >
              Stop
            </Button>
          </VStack>
        )}
      </Center>

      {isProcessing && <Text fontSize="2xl" color="red.600" mt={8}>Processing data...</Text>}

      {audioStopped && (
        <Stack spacing={12} w="100%" mt={10} p={8} bg="white" borderRadius="2xl" shadow="2xl">
          <HStack spacing={10} w="100%" justify="center">
            <SingingAdvice />
            <VocalHealthTips />
          </HStack>
          <Divider my={6} />
          <DisplayData frequencyData={capturedData} />
          <FrequencyGraph frequencyData={capturedData} />
        </Stack>
      )}

      {isAnalyzing && <FrequencyAnalyzer setFrequencyData={setFrequencyData} />}

      {/* Additional Information Section */}
      <Box w="100%" p={10} mt={12} bg="red.100" borderRadius="2xl" shadow="xl">
        <Heading size="md" color="red.800" mb={6}>Tips for Best Results</Heading>
        <Text fontSize="lg" color="gray.700">
          1. Find a quiet place to minimize background noise.
          <br />
          2. Hold the microphone close, but not too close, to your mouth.
          <br />
          3. Take deep breaths before starting to ensure a steady tone.
          <br />
          4. Relax and enjoy the process - this tool is here to help you improve!
        </Text>
      </Box>

      {/* Footer */}
      <Spacer />
      <Box w="100%" p={8} bg="red.700" color="white" borderRadius="2xl" shadow="2xl" mt={16}>
        <Flex justify="center">
          <Text fontSize="md">&copy; {new Date().getFullYear()} CS604 Algorithms & Computing Theory
Giorgi Gulisashvili, Priya Angel Dasari, Urooj Fatima, Aditya Rowthu
Pace University
Professor: Ali Motamedi. All rights reserved.</Text>
        </Flex>
      </Box>
    </VStack>
  );
}

export default App;
